import React                   from 'react';
import { graphql }             from 'gatsby';
import Img                     from 'gatsby-image';
import styled                  from '@emotion/styled'
import { Container, Row, Col } from 'react-grid-system';

import CallToAction
                   from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Dropcap     from '@interness/web-core/src/components/text/Dropcap/Dropcap';
import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Heading     from '@interness/web-core/src/components/text/Heading/Heading';
import Separator   from '@interness/web-core/src/components/structure/Separator/Separator';
import Spacer      from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper     from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

const J = styled.p`
  text-align: justify;
`;

function HistoriePage(props) {
  return (
    <>
      <HeaderImage>
        <Img fluid={props.data.headerImage.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading>Unsere Historie</Heading>
          <h3 style={{ textAlign: 'center' }}>Der geschichtliche Hintergrund der Firma Leiber</h3>
        </section>
        <section>
          <Container fluid>
            <Row>
              <Col md={8}>
                <Dropcap><h3>Gründung</h3></Dropcap>
                <J>Das genaue Gründungsdatum der Firma lässt sich nicht detailiert feststellen, da im Stadtarchiv die
                  Unterlagen von 1840 bis 1843 fehlen. Die erste Anzeige stammt vom 19. August 1843. Dort wird
                  angekündigt, dass der Uhrmacher Friedrich Wilhelm Höhmann sein Uhrmachergeschäft im elterlichem Haus
                  fortsetzt.
                  Nehmen wir dieses Datum einfach als "Geburtsstunde" der heutigen Gustav Leiber KG und skizzieren kurz
                  ihre Geschichte:
                  Friedrich Wilhelm Höhmann machte sich irgendwann zwischen 1840 und dem 19. August 1843 selbständig.
                  Nach seinem Tod übernahm sein Gehilfe Franz Wilhelm Theodor Borggreve am 6. Juni 1857 das Geschäft.
                  Dieser entwickelte das Geschäft kontinuierlich weiter, bis er - ohne sich zur Ruhe gesetzt zu haben -
                  am 9. Juli 1909 verstarb.</J>
              </Col>
              <Col md={4}>
                <img src='/historie/about-img-1.jpg' alt='Gründung'/>
              </Col>
            </Row>
            <Separator/>
            <Row>
              <Col md={4}>
                <img src='/historie/about-img-2.jpg' alt='Gründung'/>
              </Col>
              <Col md={8}>
                <Dropcap><h3>Gustav Leiber</h3></Dropcap>
                <J>Zu dieser Zeit arbeitete bereits ein Uhrmacher bei ihm, der der heutigen Firma den Namen gab: Gustav
                  Leiber.
                  Er übernahm das Geschäft am 1. November 1909. Aus seiner Ehe mit Helene Recker gingen sieben Kinder
                  hervor.
                  Ihr Sohn Erich Leiber mußte bereits mit 22 Jahren direkt nach seiner Lehrzeit dem Vater helfen, da
                  dieser sehr krank war. Er starb 1935. Nebenbei besuchte Erich Leiber die Meisterschule in Düsseldorf
                  und legte am 25. Oktober 1937 als jüngster Meister die Prüfung ab.
                  Während des 2. Weltkrieges führte seine Schwester Gertrud Leiber das Geschäft zunächst bis 1943
                  weiter, danach ruhte es bis 1945.</J>
              </Col>
            </Row>
            <Separator/>
            <Row>
              <Col md={8}>
                <Dropcap><h3>Geburtsstunde</h3></Dropcap>
                <J>Nach Einführung der DM im Juni 1948 kauften die Brüder Erich und Reinhold Leiber Uhren im Wert von
                  ca. 40.000 DM und begründeten damit die zweite Geburtsstunde der heutigen Gustav Leiber KG.
                  Nach dem Wiederaufbau 1953 eröffnete man das Geschäft auf dem Eckgrundstück Hauptstraße / Linkgasse,
                  das heute noch an gleicher Stelle besteht. Zusammen mit seiner Frau Regina und seiner Schwester
                  Gertrud führte Erich Leiber das Geschäft bis 1989.
                  Am 1. Januar 1990 zog er sich aus dem Berufsleben zurück und übergab das Geschäft seiner Tocher
                  Annette Köster. Er verstarb am 6. Januar 1996. Zusammen mit ihrem Mann Andreas führt Annette Köster
                  das Geschäft bis heute und versucht zusammen mit vier Mitarbeitern, ihre Vorstellungen eines modernen
                  Fachgeschäftes zu verwirklichen.</J>
              </Col>
              <Col md={4}>
                <img src='/historie/about-img-3.jpg' alt='Gründung'/>
              </Col>
            </Row>
            <Separator/>
            <Row>
              <Col md={12}>
                <Dropcap><h3>Seitdem</h3></Dropcap>
                <J>haben wie unser Angebot, neben klassischem Schmuck und traditionellen Uhrmarken, um viele Trendmarken
                  erweitert, so dass wir viele junge Menschen zu unserer Kundschaft zählen dürfen. Dies nutzten wir
                  parallel zum Aufbau unseres „Trauringstudio Solingen“, das sich mittlerweile zu einem der
                  bedeutendsten Trauringanbietern im Bergischen Land zählen darf. An dieser Stelle erwähnenswert bleibt
                  auch im Jahr 2014 der vergebliche Versuch eines Solinger Zahnarztes, das unser Haus
                  prägende Glockenspiel per Gericht verbieten zu lassen. Deutlicher hätten der Zuspruch der Solinger
                  Bevölkerung für das historische Instrument und auch der Richterspruch, der in allen Belangen die Klage
                  zurückwies, kaum sein können. Die kommenden Jahre werden geprägt sein vom Strukturwandel im Deutschen
                  Einzelhandel und spezifisch vom Wandel der Solinger Innenstadt … wir arbeiten daran.</J>
              </Col>
            </Row>
          </Container>
        </section>
        <Spacer/>
        <section style={{ textAlign: 'center' }}>
          <CallToAction/>
        </section>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default HistoriePage;

export const query = graphql`
  query HistoriePageQuery {
    headerImage: file(relativePath: {eq: "headers/historie.jpg"}) {
      name
      childImageSharp {
        fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;